const middleware = {}

middleware['redirectHappyKidsPage'] = require('../middleware/redirectHappyKidsPage.js')
middleware['redirectHappyKidsPage'] = middleware['redirectHappyKidsPage'].default || middleware['redirectHappyKidsPage']

middleware['redirectInvalidPath'] = require('../middleware/redirectInvalidPath.js')
middleware['redirectInvalidPath'] = middleware['redirectInvalidPath'].default || middleware['redirectInvalidPath']

middleware['zhCnRedirection'] = require('../middleware/zhCnRedirection.js')
middleware['zhCnRedirection'] = middleware['zhCnRedirection'].default || middleware['zhCnRedirection']

export default middleware
