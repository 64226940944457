
  import promoWidgetQuery from '@/apollo/queries/general/promoWidgetQuery';

  export default {
    name: 'PromoFloatingWidget',

    apollo: {
    $loadingKey: 'loading',
    promoWidget: {
      prefetch: true,
      query: promoWidgetQuery,
      variables() {
        return { currentlocale: this.$normalizeLocale(this.$i18n.locale) }
      },
      update(data) {
        return this.$normalizeStrapi(data.promoWidget);
      },
    },
  },
  
    data() {
      return {
        showPromoWidget: false,
      }
    },
  
    methods: {
      closeWidget() {
        this.setSession('promoWidget', false);
        this.showPromoWidget = false;
      },
      setSession(key, value) {
        if(process.client) {
          sessionStorage.setItem(key, value);
        }
      },
    },
  
    mounted() {
      // Check session to see whether it contains 'promoWidget'
      if(process.client) {
        let result = sessionStorage.getItem('promoWidget');
        if(!result) {
          this.showPromoWidget = true;
        }
        else if(result) {
          this.showPromoWidget = false;
        }
      }
    },  
  };
  