import BlogFilterTypeEnum from "~/enums/index.js"

export const state = () => ({
  filters: {
    tagCodes: [],
    authorCodes: [],
    activeType: 'tag',
  },
  showMobileMenu: false,
  showDesktopMenu: true
})

export const mutations = {
  clearTagCodes(state) {
    state.filters.tagCodes = [];
  },
  clearAuthorCodes(state) {
    state.filters.authorCodes = [];
  },
  setTagCodes(state, data) {
    const index = state.filters.tagCodes.indexOf(data);
    if (index !== -1) {
      state.filters.tagCodes.splice(index, 1);
    } else {
      state.filters.tagCodes.push(data)
    }
  },
  setAuthorCodes(state, data) {
    const index = state.filters.authorCodes.indexOf(data);
    if (index !== -1) {
      state.filters.authorCodes.splice(index, 1);
    } else {
      state.filters.authorCodes.push(data)
    }
  },
  setAllAuthorCodes(state, data) {
    state.filters.authorCodes = data;
  },
  setAllTagCodes(state, data) {
    state.filters.tagCodes = data;
  },
  setActiveType(state, data) {
    state.filters.activeType = BlogFilterTypeEnum[data];
  },
  setMobileMenuState(state, data) {
    state.showMobileMenu = data;
  },
  setDesktopMenuState(state, data) {
    state.showDesktopMenu = data;
  }
}

export const getters = {
  filtersTagCodes: (state) => {
    return state?.filters?.tagCodes;
  },
  filtersAuthorCodes: (state) => {
    return state?.filters?.authorCodes;
  },
  filtersActiveType: (state) => {
    return state?.filters?.activeType;
  },
  showMobileMenu: (state) => {
    return state?.showMobileMenu;
  },
  showDesktopMenu: (state) => {
    return state?.showDesktopMenu;
  }
}
