import { render, staticRenderFns } from "./PopupBannerModal.vue?vue&type=template&id=4e2bb53c&scoped=true"
import script from "./PopupBannerModal.vue?vue&type=script&lang=js"
export * from "./PopupBannerModal.vue?vue&type=script&lang=js"
import style0 from "./PopupBannerModal.vue?vue&type=style&index=0&id=4e2bb53c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e2bb53c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ReusableButton: require('/app/components/common/ReusableButton.vue').default,ModalWrapper: require('/app/components/modal/common/ModalWrapper.vue').default})
