
  import popupBannerQuery from '@/apollo/queries/general/popupBannerQuery';
  
  export default {
    name: 'PopupBannerModal',

    apollo: {
      $loadingKey: 'loading',
      popupBanner: {
        query: popupBannerQuery,
        prefetch: true,
        variables() {
          return { currentlocale: this.$normalizeLocale(this.$i18n.locale) };
        },
        update(data) {
          return this.$normalizeStrapi(data.popupBanner);
        }
      }
    },
  
    props: {

    },
  
    data() {
      return {

      }
    },
    methods: {
      confirm() {
        this.$store.commit('modal/setShowPopupBannerModal', false);
      },
     
      closeModal() {
        this.loginBox = false;
        this.$nextTick(() => {
          this.$emit('close-modal');
        });
      },
    },
  };
  