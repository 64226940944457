import createPersistedState from 'vuex-persistedstate';

export default ({ app, store, req }) => {
  createPersistedState({
    paths: ['login.user', 'strapiLogin.user', 'productCompare.category', 'productCompare.products',
      'disclaimerPopup.hasProductDisclaimerConfirmed', 'disclaimerPopup.hasBlogDisclaimerConfirmed', 'disclaimerPopup.disclaimerConfirmedBlogs',
      'myfav.blogs', 'myfav.pendingProducts'], 
    storage: {
      getItem: (key) => {
        return app.$cookies.get(key);
      },
      setItem: (key, value) =>
        app.$cookies.set(key, value, {
          path: '/',
          maxAge: 60 * 60 * 24 * 30 // cookie age = 30 Days
        }),
      removeItem: (key) => app.$cookies.remove(key)
    }
  })(store);
};