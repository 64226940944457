import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=42e68891"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"
import style0 from "./Footer.vue?vue&type=style&index=0&id=42e68891&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TutorialButton: require('/app/components/tutorial/TutorialButton.vue').default,ReusableLink: require('/app/components/common/ReusableLink.vue').default})
