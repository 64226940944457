export default function ({ route, redirect }) {
  const lowerPath = route.fullPath.toLowerCase();

  const excludePaths = [
    '/healthcheck',
    '/happy-kids/2023',
    '/en/happy-kids/2023',
    '/zh-hk/happy-kids/2023',
    '/zh-cn/happy-kids/2023'
  ];

  const isExcluded = excludePaths.some((path) => lowerPath.startsWith(path));

  if (isExcluded) {
    redirect(302, `https://happykids2023.10life.com${route.fullPath}`);
  }
}
